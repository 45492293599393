import { LinkToProcoreEntity } from "./components/link-to-procore-entity/link-to-procore-entity";
import { GanttContainer } from "./components/gantt/gantt-container";
import { LaborPlansContainer } from "./components/labor-plans/labor-plans-container";
import { TimeOffListContainer } from "./components/time-off-list/time-off-list-container";
import { IntegrationSettingsContainer } from "./components/settings/integration-settings/integration-settings-container";
import { ProjectListContainer } from "./components/project-list/project-list-container";
import { PeopleListContainer } from "./components/people-list/people-list-container";
import { NotFound } from "./components/not-found";
import { ProjectQrProfile } from "./components/qr-profiles/project-qr-profile";

const components = {
   LinkToProcoreEntity,
   NotFound,
   GanttContainer,
   LaborPlansContainer,
   TimeOffListContainer,
   IntegrationSettingsContainer,
   ProjectListContainer,
   PeopleListContainer,
   ProjectQrProfile,
} as const;

export default components;
